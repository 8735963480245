import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import styled from 'styled-components';

const BreadcrumbStyled = styled(Breadcrumb)`
  & ol.breadcrumb {
    background-color:transparent;
  }
`;

const BC = props => {
  return (
    <BreadcrumbStyled>
      {props.links &&
        props.links.map((elm, idx) => {
          const isLast = props.links.length === idx + 1;
          return (
            <Breadcrumb.Item active={isLast}>
              {elm}
            </Breadcrumb.Item>
          );
        })}
    </BreadcrumbStyled>
  );
};

export default BC;
