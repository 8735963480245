import React from "react";
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import { colors } from '../../utils/styles';

import Logo from "../../../static/mini-logo-ssc.png";

const StyledNavDropdown = styled(NavDropdown)`
  & .nav-link{
    background: #f8f9fa !important;
  }

  #responsive-navbar-nav > div > div.mr-sm-2.nav-item > a & .nav-item .active{
    background: #f8f9fa !important;
  }
`;

const Header = props => {
  return (
    <div style={{paddingTop: '56px'}}>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta
          name="google-site-verification"
          content="h3_4CWVbzwvnHDugHZM4jQH-75O2E-n0DKWSWmEThS8"
        />
        <meta
          name="google-site-verification"
          content="JzYF__b35ZvPzCbJcOYPHjTaCV58z7hTc-o-u91IAUo"
        />

        <meta
          name="description"
          content={
            props.seoDescription ||
            "狭山スイミングクラブ正式サイト: 沢山の思い出を一緒に作りましょう　！"
          }
        />
        <meta
          name="keywords"
          content={
            props.seoKeywords ||
            "狭山スイミングクラブ,狭山プール,所沢,水泳,入間,狭山スポーツ,狭山,埼玉プール,所沢プール"
          }
        />

        <title>{props.seoTitle || "狭山スイミングクラブ正式サイト"}</title>

      </Helmet>

      <Navbar bg="light" expand="lg" fixed="top" style={{borderBottom: `solid 1px ${colors.sscGray}`}}>
        <Navbar.Brand href="/">
          <img
            alt=""
            src={Logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          狭山スイミングクラブ
        </Navbar.Brand>
        
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav variant="pills" className="ml-auto">
              
                <Nav.Item className="mr-sm-2">
                  <Nav.Link eventKey="1" href="/" className="ml-auto">
                    TOP
                  </Nav.Link>
                </Nav.Item>

                <StyledNavDropdown title="コース" id="nav-dropdown-1">
                  <NavDropdown.Item eventKey="2.1" href="/children-course">
                    子供コース
                  </NavDropdown.Item>

                  <NavDropdown.Item eventKey="2.2" href="/adults-course">
                    大人コース
                  </NavDropdown.Item>

                  <NavDropdown.Item eventKey="2.3" href="/free-course">
                    フリーコース
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  <NavDropdown.Item eventKey="2.4" href="/try">
                    体験
                  </NavDropdown.Item>

                  <NavDropdown.Item eventKey="2.5" href="/about-registration">
                    届出方法について
                  </NavDropdown.Item>
                </StyledNavDropdown>

                <Nav.Item>
                  <Nav.Link eventKey="3" href="/recruit">
                    採用情報
                  </Nav.Link>
                </Nav.Item>

                <StyledNavDropdown title="アクセス" id="nav-dropdown-2">
                  <NavDropdown.Item eventKey="4.1" href="/access">
                    地図
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="4.2" href="/bus">
                    スクールバス
                  </NavDropdown.Item>
                </StyledNavDropdown>

                <Nav.Item>
                  <Nav.Link eventKey="5" href="/contact">
                    お問い合わせ
                  </Nav.Link>
                </Nav.Item>
             
            </Nav>
          </Navbar.Collapse>
        
      </Navbar>

      {/* {props.links && (
        <Container>
          <Breadcrumb links={props.links} />
        </Container>
      )} */}
    </div>
  );
};

export default Header;
