import React from "react";
import Container from "react-bootstrap/Container";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import PageTitle from "../common/PageTitle/PageTitle";
import "bootstrap/dist/css/bootstrap.min.css";

const Layout = ({
  children,
  links,
  title,
  subTitle,
  seoTitle,
  seoDescription,
  seoKeywords,
}) => {
  return (
    <>
      <Header
        links={links}
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        seoKeywords={seoKeywords}
      />
      <Container>
        <PageTitle title={title} subTitle={subTitle}/>
      </Container>
      {children}
      <Footer />
    </>
  );
};

export default Layout;
