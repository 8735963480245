import React from "react";
import styled from "styled-components";
import { device, colors } from '../../utils/styles';

const TitleH1 = styled.h1`
  text-align: center;
  color: ${colors.sscBlue};
  font-family: uzura;
  margin: 20px 0;
  border-bottom: 1px solid ${colors.sscBlue};
  font-weight: 700;

  @media ${device.mobileS} { 
    font-size:30px;
  }
  @media ${device.tablet} {
    font-size:45px;
  }

`;

const SubTitleStyled = styled.span`
  color: ${colors.sscRed};
  margin-left: 20px;
  font-weight: 400;
  font-size:20px;
  & ::before {
      content: "|";
    }
`;

const PageTitle = props => {
  return (
    <TitleH1>
      {props.title}
      { props.subTitle && <SubTitleStyled> {props.subTitle}</SubTitleStyled>}
    </TitleH1>
  );
};

export default PageTitle;
