import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { Geo, TelephoneForward } from "react-bootstrap-icons";
import { colors } from "../../utils/styles";

const DivFooter = styled.div`
  background-color: #f4f7f7;
  padding: 15px 0;
  text-align: center;
  border-top: 0.5px solid #cccfcf;
`;

const Footer = () => {
  return (
    <DivFooter>
      <Row>
        <Col sm={12}>
          <Geo style={{ color: colors.sscRed }} /> 〒350-1305 埼玉県 狭山市
          入間川1432-1
          <br />
          <TelephoneForward style={{ color: colors.sscRed }} /> 04-2959-2122
          <br />
          <div
            style={{
              color: colors.sscBlue,
              fontWeight: "700",
              fontSize: "16px",
            }}
          >
            Copyright © CANU・J, All Rights Reserved.
          </div>
        </Col>
      </Row>
    </DivFooter>
  );
};

export default Footer;
